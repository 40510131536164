<template>
  <div>
    <div class="mt-5 mb-5">
      <div v-for="(team, team_id) in teams" :key="team_id">
        <div class="d-flex align-items-center ml-2 mb-2">
          <TeamLogo :src="team.logo_url" :team_name="team.name" />
          <router-link :to="`/team/${team.id}`" class="h2 mt-3 ml-2">
            {{ team.name }}
          </router-link>
        </div>

        <AppTable
          :fields="fields"
          :items="team.players"
          :sort-by.sync="sortBy[team_id]"
          :sort-desc.sync="sortDesc[team_id]"
        >
          <template #cell(player)="data">
            <img class="agent-icon" :src="data.item.agent_icon_url" :alt="data.item.agent_name" />
            <template v-if="isCollegiate || !data.item.id">
              {{ data.item.game_name }}
            </template>
            <router-link v-else :to="`/player/${data.item.id}/stats/${matchData.info.map}`">
              {{ data.item.game_name }}
            </router-link>
          </template>

          <template #cell(k_d)="data">
            <span
              :class="{
                'text-success': data.value > 0,
                'text-danger': data.value < 0,
              }"
            >
              {{ data.value }}
            </span>
          </template>

          <template #cell(fk_fd)="data">
            <span
              :class="{
                'text-success': data.value > 0,
                'text-danger': data.value < 0,
              }"
            >
              {{ data.value }}
            </span>
          </template>
        </AppTable>
      </div>
    </div>
  </div>
</template>

<script>
import AppTable from '@/components/generic/Table.vue'
import TeamLogo from '@/components/UI/TeamLogo.vue'

export default {
  components: {
    TeamLogo,
    AppTable,
  },
  inject: ['matchData'],
  data() {
    return {
      fields: [
        {
          key: 'player',
          label: 'Team Player',
          formatter: (value, key, item) => item.game_name,
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: 'avr_round_score',
          label: 'ACS',
          class: 'text-right',
          formatter: value => Math.round((value + Number.EPSILON) * 100) / 100, // TODO refactor formatters
          sortable: true,
        },
        {
          key: 'kills',
          label: 'Kills',
          class: 'text-right',
          sortable: true,
        },
        {
          key: 'deaths',
          label: 'Deaths',
          class: 'text-right',
          sortable: true,
        },
        {
          key: 'assists',
          label: 'Assists',
          class: 'text-right',
          sortable: true,
        },
        {
          key: 'k_d',
          label: '+/- (K-D)',
          class: 'text-right',
          formatter: (value, key, item) => item.kills - item.deaths,
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: 'avr_round_damage',
          label: 'ADR',
          class: 'text-right',
          formatter: value => Math.round((value + Number.EPSILON) * 100) / 100, // TODO refactor formatters
          sortable: true,
        },
        {
          key: 'head_shot_perc',
          label: 'HS%',
          class: 'text-right',
          formatter: (value, key, item) =>
            `${Math.round((item.total_headshots / item.total_shots + Number.EPSILON) * 100)}%`,
          sortable: true,
        },
        {
          key: 'first_kills',
          label: 'FK',
          class: 'text-right',
          sortable: true,
        },
        {
          key: 'first_deaths',
          label: 'FD',
          class: 'text-right',
          sortable: true,
        },
        {
          key: 'fk_fd',
          label: '+/- (FK-FD)',
          class: 'text-right',
          formatter: (value, key, item) => item.first_kills - item.first_deaths,
          sortable: true,
          sortByFormatted: true,
        },
      ],
      sortBy: {},
      sortDesc: {},
    }
  },
  computed: {
    teams() {
      return this.matchData.info.teams
    },

    isCollegiate() {
      return this.$route.params.isCollegiate || false
    },
  },
  watch: {
    teams: {
      immediate: true,
      deep: true,
      handler(teams) {
        if (teams) {
          for (const team_id in teams) {
            if (!(team_id in this.sortBy)) {
              this.$set(this.sortBy, team_id, 'player')
            }
            if (!(team_id in this.sortDesc)) {
              this.$set(this.sortDesc, team_id, false)
            }
          }
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.agent-icon {
  height: 40px;
}

.team-logo {
  max-width: 50px;
  max-height: 60px;
}
</style>
