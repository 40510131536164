<template>
  <div class="flex-fill d-flex justify-content-center mt-4 mb-4">
    <MiniSpinner class="mr-2" />
    {{ label ? `${label}...` : '' }}
  </div>
</template>

<script>
import px from 'vue-types'

import MiniSpinner from './MiniSpinner.vue'

export default {
  name: 'AppLoading',
  components: { MiniSpinner },
  props: {
    label: px.string.def('Loading'),
  },
}
</script>
