<template>
  <BSpinner small label="Loading..." />
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'MiniSpinner',
  components: { BSpinner },
}
</script>

<style lang="scss" scoped></style>
