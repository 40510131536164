<template>
  <img v-if="src" :src="src" class="team-logo" :alt="team_name" @error="set_default_logo" />
</template>

<script>
export default {
  props: {
    size: String,
    src: String,
    team_name: String,
  },

  methods: {
    set_default_logo(e) {
      e.target.src = '/images/teams/no-logo.png'
    },
  },
}
</script>
